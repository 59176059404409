import React, { useState } from "react";
import JumboWrapper from "../components/Jumbotron/JumboWrapper";
import JumboItem from "../components/Jumbotron/JumboItem";
import JumboTextWrapper from "../components/Jumbotron/JumpoTextWrapper";
import JumboTitle from "../components/Jumbotron/JumboTitle";
import JumboSubTitle from "../components/Jumbotron/JumboSubTitle";
import JumboImageWrapper from "../components/Jumbotron/JumboImageWrapper";
import JumboImage from "../components/Jumbotron/JumboImage";
import JumboData from "../data/jumbo.json";

function JumboCompound() {
  const [isEven] = useState(false);

  return (
    <JumboWrapper>
      {JumboData.map((item, index) => {
        const showImageFirst =
          (index % 2 === 0 && isEven) || (index % 2 !== 0 && !isEven);
        return (
          <JumboItem key={item.id}>
            {showImageFirst ? (
              <>
                <JumboImageWrapper>
                  <JumboImage src={item.image} alt={item.alt} />
                </JumboImageWrapper>
                <JumboTextWrapper>
                  <JumboTitle>{item.title}</JumboTitle>
                  <JumboSubTitle>{item.subTitle}</JumboSubTitle>
                </JumboTextWrapper>
              </>
            ) : (
              <>
                <JumboTextWrapper>
                  <JumboTitle>{item.title}</JumboTitle>
                  <JumboSubTitle>{item.subTitle}</JumboSubTitle>
                </JumboTextWrapper>
                <JumboImageWrapper>
                  <JumboImage src={item.image} alt={item.alt} />
                </JumboImageWrapper>
              </>
            )}
          </JumboItem>
        );
      })}
    </JumboWrapper>
  );
}

export default JumboCompound;
