import React, { useState } from "react";
import HeaderCompound from "../compounds/HeaderCompound";
import JumboCompound from "../compounds/JumboCompound";
import AccordionCompound from "../compounds/AccordionCompound";
import Slider from "../compounds/Slider";
import MyModalNew from "../compounds/MyModalNew";

function HomePage() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <HeaderCompound />
      <div className="container">
        <div id="onceplay">
          <JumboCompound />
        </div>
        <div className="new-device">
          <h1 className="feature-heading-new-2">
            ¡Únicos con más de 65 canales ecuatorianos!
            <br />
            <span style={{ fontStyle: 'italic', fontWeight: 300 }}>
              {" "}
              y más de 120 canales premium latinos e internacionales
            </span>
          </h1>
        </div>
        <div id="canales" className="container-slider">
          <Slider />
        </div>
        <div className="device">
          <h1 className="feature-heading-new-3">
            Tu portal de entretenimiento sin cables ni antenas
            <br />
            <span
              style={{
                fontWeight: 300,
                fontSize: "1.5rem",
                lineHeight: "normal",
              }}
            >
              {" "}
              Lo mejor de todo es que puedes disfrutar de OncePlay sin la
              molestia de cables o antenas. Diseñado para funcionar sin
              problemas en dispositivos Android, Apple y Smart Tv y otros
              dispositivos compatibles como Android TV, es la solución perfecta
              para tu hogar.
            </span>
          </h1>
          <br />
          <br />
          <div className="btn-container">
            <button type="button" className="btn-new button" onClick={openModal}>
              VER DISPOSITIVOS
            </button>
          </div>
        </div>
        <div id="preguntas-frecuentes">
          <AccordionCompound />
        </div>
        <div className="device">
          <h1 className="feature-heading-new">
            ¡Descubre un mundo de posibilidades con OncePlay y deja que la
            diversión comience!
            <br />
          </h1>
        </div>
        <div className="btn-container">
          <button type="button" className="btn-new button">¡Contrátalo aquí!</button>
        </div>
        <br />
        <br />
        <br />
      </div>
      <MyModalNew isOpen={isModalOpen} onRequestClose={closeModal} />
    </>
  );
}

export default HomePage;
