import React from 'react';
import Modal from 'react-modal';

Modal.setAppElement('#root');

const MyModal = ({ isOpen, onRequestClose, imageTitle, data }) => {

  const results = data.length > 0 ? data[0].result : [];

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Image Modal"
      className="modal-new-2"
    >
      <h2 className='h2-modal'>{imageTitle}</h2>

      <button onClick={onRequestClose} className="close-button">x</button>

      <div className="modal-content-container">
        <div className="row">
          {results.map((item, index) => (
            <div key={index} className="col-new">
              <img src={item.url_logo} alt="Logo" className='img-modal' />
              {/* <p className='p-modal'>{item.canal}</p> */}
            </div>
          ))}
        </div>
      </div>
    </Modal>
  );
};

export default MyModal;
