import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import HeaderWrapper from "../components/Header/HeaderWrapper";
import NavBar from "../components/Header/NavBar";
import Logo from "../components/Header/Logo";
import SignFormWrapper from "../components/SignForm/SignFormWrapper";
import SignFormBase from "../components/SignForm/SignFormBase";
import SignFormTitle from "../components/SignForm/SignFormTitle";
import SignFormInput from "../components/SignForm/SignFormInput";
import SignFormButton from "../components/SignForm/SignFormButton";
import SignFormError from "../components/SignForm/SignFormError";
import Warning from "../components/Header/Warning";

function SignupPage() {
  const history = useHistory();
  const [firstName, setFirstName] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const isInvalid = password === "" || emailAddress === "" || firstName === "";

  function handleSubmit(event) {
    event.preventDefault();
    if (isInvalid) {
      setError("Todos los campos son obligatorios.");
      return;
    }
    console.log("Nombre:", firstName);
    console.log("Correo electrónico:", emailAddress);
    console.log("Contraseña:", password);
    history.push("/signup");
  }

  return (
    <>
      <HeaderWrapper className="header-wrapper-home-signup">
        <NavBar className="navbar-signin">
          <Logo />
        </NavBar>
        <SignFormWrapper>
          <SignFormBase onSubmit={handleSubmit} method="POST">
            <Warning>OncePlay</Warning>
            <SignFormTitle>Registrarse</SignFormTitle>
            {error ? <SignFormError>{error}</SignFormError> : null}
            <SignFormInput
              type="text"
              placeholder="Nombre"
              value={firstName}
              onChange={({ target }) => setFirstName(target.value)}
            />
            <SignFormInput
              type="text"
              placeholder="Correo electrónico"
              value={emailAddress}
              onChange={({ target }) => setEmailAddress(target.value)}
            />
            <SignFormInput
              type="password"
              placeholder="Contraseña"
              autoComplete="off"
              value={password}
              onChange={({ target }) => setPassword(target.value)}
            />
            <SignFormButton disabled={isInvalid}>Registrar</SignFormButton>
          </SignFormBase>
        </SignFormWrapper>
      </HeaderWrapper>
    </>
  );
}

export default SignupPage;
