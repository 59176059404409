import React, { useState } from "react";
import "./HeaderStyles.css";


function HeaderLink() {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <div className="header-link-container">
      <div className="menu-icon" onClick={toggleMenu}>
        &#9776;
      </div>
      <div className={`header-link ${menuOpen ? "open" : ""}`}>
        <a href="#onceplay">Sobre OncePlay</a>
        <a href="#canales">Canales</a>
        <a href="#preguntas-frecuentes">Preguntas Frecuentes</a>
      </div>
    </div>
  );
}

export default HeaderLink;
