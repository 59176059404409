import React, { useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import MyModal from "./MyModal";
const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
    slidesToSlide: 1
  },
  tablet: {
    breakpoint: { max: 1024, min: 768 },
    items: 3,
    slidesToSlide: 1
  },
  mobile: {
    breakpoint: { max: 767, min: 0 },
    items: 2,
    slidesToSlide: 1
  },
  minimobile: {
    breakpoint: { max: 550, min: 0 },
    items: 1,
    slidesToSlide: 1
  }
};
const sliderImageUrl = [
  {
    url:
      "/images/misc/NACIONAL.png",
    title: "NACIONALES"
  },
  {
    url:
      "/images/misc/CINE.png",
    title: "CINE"
  },
  {
    url:
      "/images/misc/INTERNACIONAL.png",
    title: "INTERNACIONALES",
  },
  {
    url:
      "/images/misc/DEPORTE.png",
    title: "DEPORTES"
  },
  {
    url:
      "/images/misc/ENTRETENIMIENTO.png",
    title: "ENTRETENIMIENTO"
  },
  {
    url:
      "/images/misc/INFANTIL.png",
    title: "INFANTILES"
  },
  {
    url:
      "/images/misc/VARIEDADES.png",
    title: "VARIEDADES"
  },
  {
    url:
      "/images/misc/NOTICIAS.png",
    title: "NOTICIAS",
  }
];
const Slider = () => {

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedImageTitle, setSelectedImageTitle] = useState("");
  const [selectedImageData, setSelectedImageData] = useState("");

  const openModal = (title, data) => {
    console.log('Opening modal with title:', title);
    setSelectedImageTitle(title);
    setModalIsOpen(true);
    setSelectedImageData(data);

  };

  const closeModal = () => {
    console.log('Closing modal');
    setModalIsOpen(false);
  };

  const handleImageClick = async (title) => {
    try {
      console.log('handleImageClick called with title:', title);

      const response = await fetch('https://api.once.net.ec/xui/api/list-streams-category', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ category: title })
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();

      openModal(title, data);
    } catch (error) {
      console.error('Hubo un problema con la operación de búsqueda:', error);
    }
  };


  return (
    <div className="parent">
      <Carousel
        responsive={responsive}
        autoPlay={false}
        swipeable={true}
        draggable={true}
        showDots={false}
        infinite={true}
        partialVisible={false}
        dotListClass="custom-dot-list-style"
      >
        {sliderImageUrl.map((imageUrl, index) => {
              console.log('Rendering image:', imageUrl);

          return (
            <div className="slider" key={index} onClick={() => handleImageClick(imageUrl.title, imageUrl.title)}>
              <img src={imageUrl.url} alt="movie" />
            </div>
          );
        })}
      </Carousel>
      <MyModal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        imageTitle={selectedImageTitle}
        data={selectedImageData}
      />
    </div>
  );
};
export default Slider;
