import React from 'react';
import Modal from 'react-modal';

Modal.setAppElement('#root');

const MyModalNew = ({ isOpen, onRequestClose }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Image Modal"
      className="modal-new"
    >
      <h2 className='title-modal-2'>DISPOSITIVOS DISPONIBLES</h2>
      <p className='text-modal-2'>Con OncePlay, disfruta de la libertad de acceder a tus contenidos preferidos en cualquier momento y lugar, directamente desde tus dispositivos móviles y de escritorio.</p>
      <div className="row">
        <div className="col">
          <img src="/images/misc/androidtv.png" alt="Imagen 2" className='img-new' />
        </div>
        <div className="col">
          <img src="/images/misc/samsungtv.png" alt="Imagen 1" className='img-new' />
        </div>
        <div className="col">
          <img src="/images/misc/apletv.png" alt="Imagen 2" className='img-new' />
        </div>
        <div className="col">
          <img src="/images/misc/androidtabletsetc.png" alt="Imagen 2" className='img-new' />
        </div>
        <div className="col">
          <img src="/images/misc/googletv.png" alt="Imagen 2" className='img-new' />
        </div>

      </div>
      <button onClick={onRequestClose} className="close-button">x</button>
    </Modal>
  );
};

export default MyModalNew;
